import * as request from "@/services/request";
import * as login from "@/services/login";
import * as album from "@/services/album"
import * as albumImage from "@/services/albumImage"

export default {
    request,
    login,
    album,
    albumImage,
}