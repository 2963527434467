import React, {useEffect, useState} from "react";
import {User} from "@/services/login";
import {fetchUserInfo, logout} from "@/utils/User";
import {Link, useNavigate} from "react-router-dom";
import api from "@/services/api";
import usePage from "@/hooks/usePage";
import {ImageGalleryAlbum} from "@/services/album";

const UserProfile: React.FC = () => {

    const [user, setUser] = useState<User>();
    const [errMsg, setErrMsg, items, refreshPage, nextPage, more]
        = usePage<ImageGalleryAlbum>(api.album.listAlbum);
    const negative = useNavigate();

    useEffect(
        () => {
            fetchUserInfo()
                .then((user) => {
                    setUser(user);
                })
                .catch((reason) => {
                    negative("/login");
                })
        },
        []
    )

    const codeToSex = (code: string | undefined) => {
        if (code === '0') {
            return '男';
        }

        if (code === '1') {
            return '女';
        }

        return '未知';
    }

    const logoutHandler = () => {
        logout()
            .finally(() => {
                negative("/login");
            })
    }

    return (
        <div>
            {
                errMsg && <p>{errMsg}</p>
            }
            {
                user && (
                    <div>
                        <div>
                            <p>头像：</p>
                            <img className="h-12 w-12 flex-none rounded-full bg-gray-50"
                                 src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                 alt=""/>
                            <p>用户名：</p>
                            <p>{user.userName}</p>
                            <p>性别：</p>
                            <p>{codeToSex(user.sex)}</p>
                        </div>
                        <div>
                            <button onClick={logoutHandler}>退出登录</button>
                        </div>
                    </div>
                )
            }
            <div>
                <button onClick={() => {
                    api.album.createAlbum()
                        .then((res) => {
                            if (res?.data?.success) {
                                refreshPage()
                            } else {
                                setErrMsg(res?.data?.errorMessage)
                            }
                        })
                        .catch((reason) => {
                            setErrMsg(reason?.toString())
                        })
                }}>
                    新建相册
                </button>
                <ul>
                    {
                        items.rows.map((item, index) => {
                            return (
                                <li key={index} className="flex justify-between gap-x-6 py-5">
                                    <div>
                                        <Link to={`/album/${item.accessSecret}`}>{`/album/${item.accessSecret}`}</Link>
                                        <div>{item.createTime}</div>
                                    </div>
                                    <div>
                                        <button>删除</button>
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
                {
                    more && <button onClick={nextPage}>更多</button>
                }
            </div>
        </div>
    )
}

export default UserProfile;