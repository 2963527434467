import React, {JSX, useMemo, useState} from "react";
import _ from "lodash";
import {useForm} from "react-hook-form";
import {LoginInfo} from "@/services/login";
import {login} from "@/utils/User";
import {useNavigate} from "react-router-dom";
import {QRCodeSVG} from "qrcode.react";
import {useBreakpoint} from "@/hooks/useBreakpoints";
import {BREAKPOINT} from "@/utils/Tailwind";

const SelfLoginForm: React.FC = () => {

    const [errMsg, setErrMsg] = useState<string | undefined>(undefined);

    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm<LoginInfo>();

    const submitLogin = (loginInfo: LoginInfo) => {
        return login(loginInfo)
            .then(() => {
                navigate("/user/profile")
            })
            .catch((reason) => {
                setErrMsg(reason?.toString());
            })
    }

    const inputStyle = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg" +
        " focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700" +
        " dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" +
        " dark:focus:ring-blue-500 dark:focus:border-blue-500";

    return (
        <div className="flex flex-col">
            <form onSubmit={handleSubmit(submitLogin)}>
                <div className="flex flex-col mb-5">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        账号
                        <input
                            className={inputStyle}
                            {...register("userName", {required: true})}
                        />
                    </label>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        密码
                        <input
                            className={inputStyle}
                            type="password"
                            {...register("password", {required: true})}
                        />
                    </label>
                </div>
                <div className="flex flex-row space-x-5">
                    <button type="submit"
                            className="text-white bg-sky-600 hover:bg-sky-500 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        登录
                    </button>
                    <button type="submit"
                            className="bg-white hover:bg-sky-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        注册
                    </button>
                </div>
                {
                    errMsg && <p className="overflow-hidden">{errMsg}</p>
                }
            </form>
        </div>
    )
}

const PhoneLoginForm: React.FC = () => {

    const inputStyle = "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg" +
        " focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700" +
        " dark:border-gray-600 dark:placeholder-gray-400 dark:text-white" +
        " dark:focus:ring-blue-500 dark:focus:border-blue-500";

    return (
        <div className="flex flex-col">
            <div className="flex flex-col mb-5">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    手机号
                    <input className={inputStyle} type="tel"></input>
                </label>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    验证码
                    <input className={inputStyle} type="text"></input>
                </label>
            </div>
            <div>
                <button
                    className="text-white bg-sky-600 hover:bg-sky-500 focus:ring-4 focus:outline-none focus:ring-sky-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    登录/注册
                </button>
            </div>
        </div>
    );
}

const Tabs: React.FC<{ tabs: { title: string, props: JSX.Element }[] }> = (props) => {
    const [selected, setSelected] = useState<string>('0');

    const titles = useMemo(
        () => _.map(props.tabs, (value) => {
            return value.title;
        }),
        [props.tabs]
    )

    const components = useMemo(
        () => _.map(props.tabs, (value) => value.props),
        [props.tabs]
    )

    const handleTabClick = (index: string) => {
        setSelected(index);
    }

    return (
        <>
            <div
                className="flex flex-wrap flex-row justify-center font-medium border-b border-gray-200 dark:border-gray-700 mb-5 space-x-5">
                {
                    _.map(titles, (value, index) => {
                        return <button key={index} id={index.toString()} onClick={() => {
                            handleTabClick(index.toString());
                        }}>{value}</button>
                    })
                }
            </div>
            {
                selected && components[parseInt(selected)]
            }
        </>
    )
}

const QRCodeLogin: React.FC<{ size: number }> = (props) => {

    return (
        <>
            <QRCodeSVG value="www.aboutdata.top" size={props.size}/>
        </>
    )
}

const Login: React.FC = () => {

    const isMD = useBreakpoint(BREAKPOINT.MD)

    return (
        <div className="flex flex-col items-center justify-center h-full">
            <div
                className="flex flex-col bg-white rounded-lg md:border-2 shadow dark:border dark:bg-gray-800 dark:border-gray-700 w-full md:w-[768px] h-fit">
                <div className="flex md:flex-row grow m-5">
                    {isMD &&
                        <div className="flex basis-1/3 flex-col border-r-2 items-center p-5 space-y-5">
                            <span>扫描二维码登录</span>
                            <QRCodeLogin size={196}/>
                        </div>
                    }
                    <div className="flex flex-col basis-full md:basis-2/3 p-5 space-y-5">
                        <div className="grow">
                            <Tabs tabs={[
                                {title: "密码登录", props: <SelfLoginForm/>} as {
                                    title: string,
                                    props: JSX.Element
                                },
                                {title: "短信登录", props: <PhoneLoginForm/>} as {
                                    title: string,
                                    props: JSX.Element
                                }
                            ]}></Tabs>
                        </div>
                        <div className="flex flex-col">
                            <div className="m-auto">其他方式登录</div>
                            <div className="flex flex-row justify-center">
                                <div>
                                    <button className="inline-flex items-center">
                                        <img
                                            src="https://open.weixin.qq.com/zh_CN/htmledition/res/assets/res-design-download/icon24_appwx_logo.png"
                                            alt="wechat"/>
                                        微信登录
                                    </button>
                                </div>
                                {/*<div>微博登录</div>*/}
                                {/*<div>QQ登录</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center m-5">
                    <span>未注册过的手机号，自动注册为账号</span>
                </div>
            </div>
        </div>
    )
}

export default Login;