import {getUserInfo, LoginInfo, postLogin, postLogout, User} from "@/services/login";

const ACCESS_TOKEN_KEY = "access_token";
const EXPIRE_TIME_KEY = "expire_time";

/**
 * milliseconds
 */
const EXPIRE_TIME = 1000 * 12 * 60 * 60

export const CHECK_REGIN_TIME = 5 * 60 * 1000;

const setSessionToken = (accessToken: string | undefined, expireTime: number) => {
    if (accessToken) {
        localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    } else {
        localStorage.removeItem(ACCESS_TOKEN_KEY);
    }

    localStorage.setItem(EXPIRE_TIME_KEY, expireTime.toString());
}

export const getAccessToken = () => {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export const getExpireTime = () => {
    return localStorage.getItem(EXPIRE_TIME_KEY);
}

export const cleanSessionToken = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(EXPIRE_TIME_KEY);
}

export const login = (loginInfo: LoginInfo) => {
    return postLogin(loginInfo)
        .then((res) => {
            if (res?.data?.success) {
                setSessionToken(res.data.data?.token, new Date().getTime() + EXPIRE_TIME);
            } else {
                return Promise.reject(res?.data?.errorMessage)
            }
        })
}

export const fetchUserInfo: () => Promise<User | undefined> = () => {
    return getUserInfo()
        .then((res) => {
            if (res?.data?.success) {
                return res.data.data?.user;
            } else {
                return Promise.reject(res?.data?.errorMessage);
            }
        })
}

export const logout = () => {
    return postLogout()
        .then((res) => {
            if (res?.data?.success) {
                return;
            } else {
                return Promise.reject(res?.data?.errorMessage);
            }
        })
        .finally(() => {
            cleanSessionToken();
        })
}
