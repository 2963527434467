import React from 'react';
import './App.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Login from "@/pages/Login";
import UserProfile from "@/pages/UserProfile";
import Album from "@/pages/Album";
import Layout from "@/components/Layout";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout/>,
        children: [
            {
                index: true,
                element: <UserProfile/>
            },
            {
                path: "/login",
                element: <Login/>
            },
            {
                path: "/user/profile",
                element: <UserProfile/>
            },
            {
                path: "/album/:secret",
                element: <Album/>
            }
        ]
    }
])

function App() {
    return (
        <>
            <div className="bg-white">
                <RouterProvider router={router}/>
            </div>
        </>
    );
}

export default App;
