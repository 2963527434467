import React from "react";
import {Link} from "react-router-dom";

export const Footer: React.FC = () => {
    return (
        <>
            <footer className="bg-white shadow dark:bg-gray-800">
                <div
                    className="w-full max-w-screen-xl p-4 md:flex md:items-center text-sm text-gray-500 sm:text-center md:mx-auto flex flex-wrap dark:text-gray-400 md:space-x-4">
                    <span>© AlinkLab 2023 版权所有。</span>
                    <Link to="https://beian.miit.gov.cn/" target="_blank">备案号： 京ICP备2022028864号</Link>
                </div>
            </footer>
        </>
    )
}
