import React, {useCallback, useEffect, useMemo, useState} from "react";
import {TableDataInfo, TableRequestParam} from "@/services/types";
import {AxiosResponse} from "axios";
import {Response} from "@/services/base";

export type UsePageRetType<T> = [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>,
    TableDataInfo<T>,
    () => void,
    () => void,
    boolean,
    boolean
]

export type UsePageParam<T> = (tableRequestParam: TableRequestParam) => Promise<AxiosResponse<Response<TableDataInfo<T>>>>

const usePage: <T>(action: UsePageParam<T>) => UsePageRetType<T> = <T>(action: UsePageParam<T>) => {
    const [errMsg, setErrMsg] = useState<string>();

    const defaultPageParam = {
        current: 1,
        pageSize: 10
    }

    const defaultItems = {
        total: 0,
        rows: []
    }

    const [pageParam, setPageParam] = useState<TableRequestParam>(defaultPageParam);

    const [items, setItems] = useState<TableDataInfo<T>>(defaultItems);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
            setLoading(true);

            action(pageParam)
                .then((res) => {
                    if (res?.data?.success) {
                        if (!pageParam.current || !pageParam.pageSize) {
                            setPageParam(defaultPageParam)
                            return;
                        }

                        const current = pageParam.current - 1;
                        const pageSize = pageParam.pageSize;

                        const start = current * pageSize;

                        if (start >= items.rows.length) {
                            setItems({
                                total: res?.data?.data?.total!,
                                rows: items.rows.concat(res?.data?.data?.rows!)
                            })

                            return;
                        }

                        setItems({
                            total: res?.data?.data?.total!,
                            rows: [
                                ...items.rows.slice(0, start),
                                ...res?.data?.data?.rows!,
                                ...items.rows.slice(start + res?.data?.data?.rows.length!)
                            ]
                        })
                    } else {
                        setErrMsg(res.data.errorMessage)
                    }
                })
                .catch((reason) => {
                    setErrMsg(reason?.toString())
                })
                .finally(() => {
                    setLoading(false);
                })
        },
        [pageParam]
    )

    const refreshPage = useCallback(() => {
        setItems(defaultItems)
        setPageParam(defaultPageParam)
    }, [])

    const nextPage = useCallback(() => {
        setPageParam({
            current: pageParam.current ? pageParam.current + 1 : 1,
            pageSize: 10
        })
    }, [pageParam])

    const more = useMemo(() => {
        return items.total > items.rows.length
    }, [items])

    return [
        errMsg,
        setErrMsg,
        items,
        refreshPage,
        nextPage,
        more,
        loading
    ]
}

export default usePage;