import {useMediaQuery} from "react-responsive";
import {screens} from "@/utils/Tailwind";

const breakpoints = screens;

type BreakpointKey = keyof typeof breakpoints;

// https://stackoverflow.com/questions/59982018/how-do-i-get-tailwinds-active-breakpoint-in-javascript
export function useBreakpoint<K extends BreakpointKey>(breakpointKey: K) {
    return useMediaQuery({
        query: `(min-width: ${breakpoints[breakpointKey]})`,
    });
}