import requestClient from "@/services/request";
import {Response} from "@/services/base";
import {AxiosResponse} from "axios";

export interface ImageGalleryAlbumImage {
    id: number,
    albumId: number,
    imageName?: string,
    imageType?: string,
    createTime?: string,
    latitude?: number,
    longitude?: number,
    imageTime?: string,
    width?: number,
    height?: number,
}

export interface Thumb {
    type: string,
    id: number
}

export interface LayoutImage2x2 {
    thumbs: Thumb[],

    imageIds: number[],

    xs: number[],

    ys: number[];
}

export type LayoutImage2x2s = LayoutImage2x2[]

export const list = (secret: string) => {
    return requestClient.get<Response<LayoutImage2x2s>,
        AxiosResponse<Response<LayoutImage2x2s>>, null>(
        "/api/v1/image-gallery/album-image/list",
        {
            headers: {
                'x-alinklab-access-secret': secret
            }
        }
    );
}

export const upload = (file: Blob, name: string, secret: string) => {
    return requestClient.put<Response<void>, AxiosResponse<Response<void>>, Blob>(
        "/api/v1/image-gallery/album-image/upload",
        file,
        {
            headers: {
                'Content-Type': file.type,
                'x-alinklab-access-secret': secret
            },
            params: {
                name: name
            },
        }
    );
}

export const download = (imageId: number, secret: string) => {
    return requestClient.get<Blob, AxiosResponse<Blob>, null>(
        "/api/v1/image-gallery/album-image/download",
        {
            headers: {
                'x-alinklab-access-secret': secret
            },
            params: {
                id: imageId
            },
            responseType: 'blob'
        }
    )
}

export const thumbnail = (imageId: number, secret: string, type: string, level: number) => {
    return requestClient.get<Blob, AxiosResponse<Blob>, null>(
        "/api/v1/image-gallery/album-image/thumbnail",
        {
            headers: {
                'x-alinklab-access-secret': secret
            },
            params: {
                id: imageId,
                type: type,
                level: level,
            },
            responseType: 'blob'
        }
    )
}

export const thumbnailLayout = (thumb: Thumb, secret: string) => {
    return requestClient.get<Blob, AxiosResponse<Blob>, null>(
        "/api/v1/image-gallery/album-image/thumbnail-layout",
        {
            headers: {
                'x-alinklab-access-secret': secret
            },
            params: thumb,
            responseType: 'blob'
        }
    )
}

export interface IdType {
    id: number
}

export const cover = (secret: string) => {
    return requestClient.get<Response<IdType>, AxiosResponse<Response<IdType>>, null>(
        "/api/v1/image-gallery/album-image/cover",
        {
            headers: {
                'x-alinklab-access-secret': secret
            },
        }
    );
}
