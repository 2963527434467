import React, {useCallback, useState} from "react";
import {PhotoIcon} from "@heroicons/react/24/solid";
import _ from "lodash";
import api from "@/services/api";

enum FileUploadStatus {
    PENDING = 'PENDING',
    DONE = 'DONE',
    FAILED = 'FAILED',
}

interface FileType {
    file: File,
    status: FileUploadStatus,
    errMsg?: string
}

const UploadImages: React.FC<{ secret: string }> = (props) => {

    const [fileListStatus, setFileListStatus] = useState<FileType[]>([]);

    const uploadFiles = useCallback((f: FileType[]) => {
        const len = fileListStatus?.length ?? 0;

        setFileListStatus(
            [
                ...fileListStatus,
                ...f
            ]
        )

        _.map(f, (item, index) => {
            api.albumImage.upload(item.file, item.file.name, props.secret)
                .then((res) => {
                    if (res.data.success) {
                        setFileListStatus((prevState) => {
                            return [
                                ...prevState?.slice(0, len + index),
                                {
                                    ...item,
                                    status: FileUploadStatus.DONE,
                                },
                                ...prevState?.slice(len + index + 1)
                            ]
                        })
                        return;
                    } else {
                        return Promise.reject(res.data.errorMessage)
                    }
                })
                .catch((reason) => {
                    setFileListStatus((prevState) => {
                        return [
                            ...prevState?.slice(0, len + index),
                            {
                                ...item,
                                status: FileUploadStatus.FAILED,
                                errMsg: reason?.toString()
                            },
                            ...prevState?.slice(len + index + 1)
                        ]
                    })
                })
        })
    }, [fileListStatus])

    return (
        <>
            <div>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                    <div className="text-center">
                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true"/>
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                            >
                                <span>Upload files</span>
                                <input id="file-upload"
                                       name="file-upload"
                                       type="file"
                                       multiple
                                       className="sr-only"
                                       onChange={(event) => {
                                           event.preventDefault();
                                           event.stopPropagation();
                                           uploadFiles(_.map(event.currentTarget.files, (item) => {
                                               return {
                                                   file: item,
                                                   status: FileUploadStatus.PENDING
                                               };
                                           }))
                                       }}/>
                            </label>
                            <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                    </div>
                </div>
            </div>
            <div className="result">
                <ul role="list" className="divide-y divide-gray-100">
                    {
                        _.map(fileListStatus, (item, index) => {
                            return (
                                <li key={index} className="flex justify-between gap-x-6 py-5">
                                    <div className="flex min-w-0 gap-x-4">
                                        {
                                            item.file.name
                                        }
                                    </div>
                                    <div className="flex flex-col items-end">
                                        <div>{item.status}</div>
                                        {
                                            item.errMsg && <div>{item.errMsg}</div>
                                        }
                                    </div>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </>
    );
}

export default UploadImages;