import {Outlet} from "react-router-dom";
import {Footer} from "@/components/Footer";
import React from "react";

const MainContainer: React.FC<{ children: React.ReactNode }> = (props) => {
    return (
        <div className="grow">
            {
                props.children
            }
        </div>
    )
}

const Layout = () => {
    return (
        <div className="flex flex-col h-screen">
            <MainContainer>
                <Outlet/>
            </MainContainer>
            <Footer/>
        </div>
    )
}

export default Layout
