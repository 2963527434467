import axios from "axios";
import {cleanSessionToken, getAccessToken, getExpireTime} from "@/utils/User";
import {redirect} from "react-router-dom";

export const AUTH_HEADER = "x-alinklab-authorization"

export const USELESS_AUTH_WHITELIST = [
    new RegExp("/auth/login"),
    new RegExp("/image-gallery/album-image")
]
axios.interceptors.request.use(
    (config) => {
        const header = config.headers;

        if (header.has(AUTH_HEADER)) {
            return config;
        }

        if (!config.url) {
            return config;
        }

        let i: number;

        for (i = 0; i < USELESS_AUTH_WHITELIST.length; ++i) {
            if (USELESS_AUTH_WHITELIST[i].test(config.url)) {
                return config;
            }
        }

        const expireTime = getExpireTime();

        if (expireTime) {
            const left = parseInt(expireTime) - new Date().getTime();
            const token = getAccessToken();
            if (left < 0) {
                cleanSessionToken();
                redirect("/login");
            } else {
                if (token) {
                    header.set(AUTH_HEADER, `Bearer ${token}`)
                } else {
                    cleanSessionToken();
                    redirect("/login");
                }
            }
        } else {
            cleanSessionToken();
        }

        return config;
    }
)

const requestClient = axios;

export default requestClient;
