import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ListImages from "@/components/ListImages";
import api from "@/services/api";
import UploadImages from "@/components/UploadImages";

const Album: React.FC = () => {

    const {secret} = useParams();

    const [cover, setCover] = useState<string | null>(null);

    useEffect(() => {
        if (secret) {
            api
                .albumImage
                .cover(secret)
                .then((res) => {
                    if (res.data.success && res.data.data?.id) {
                        return api
                            .albumImage
                            .thumbnail(res.data.data.id, secret, 'w', 0)
                            .then((res) => {
                                setCover(URL.createObjectURL(res.data));
                            })
                    } else {
                        return Promise.reject(res.data.errorMessage)
                    }
                })
                .catch((reason) => {
                    console.log(reason);
                })
        }

    }, [secret])

    return (
        <>
            {
                secret && (
                    <>
                        <UploadImages secret={secret}/>
                        {
                            cover && <img
                                src={cover}
                                alt="Tall slender porcelain bottle with natural clay textured body and cork stopper."
                                className="w-fit object-cover object-center group-hover:opacity-75 place-content-center"
                            />
                        }
                        <ListImages secret={secret}/>
                    </>
                )
            }
        </>
    );
}

export default Album;