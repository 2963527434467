import {AxiosResponse} from "axios";
import {Response} from "@/services/base";
import requestClient from "@/services/request";

export interface RegisterInfo {
    userName: string,

    password: string,

    nickName: string,

    email: string,

    code: string
}

export const postRegister = (registerInfo: RegisterInfo): Promise<AxiosResponse<Response<void>>> => {
    return requestClient.post<Response<void>, AxiosResponse<Response<void>, RegisterInfo>>("/api/v1/auth/register", registerInfo);
}

export interface LoginInfo {
    userName: string,

    password: string
}

export interface LoginToken {
    token: string
}

export const postLogin = (loginInfo: LoginInfo): Promise<AxiosResponse<Response<LoginToken>>> => {
    return requestClient.post<Response<LoginToken>, AxiosResponse<Response<LoginToken>, RegisterInfo>>("/api/v1/auth/login", loginInfo);
}

export interface User {
    id: number,
    userName: string,
    nickname?: string,
    email?: string,
    phoneNumber?: string,
    sex?: string,

    [k: string]: any
}

export interface UserInfo {
    user: User
}

export const getUserInfo = () => {
    return requestClient.get<Response<UserInfo>, AxiosResponse<Response<UserInfo>>, null>("/api/v1/core/user/info");
}

export const postLogout = (): Promise<AxiosResponse<Response<undefined>>> => {
    return requestClient.post<Response<undefined>, AxiosResponse<Response<undefined>>, undefined>("/api/v1/auth/logout");
}
