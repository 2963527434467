import {MutableRefObject, useEffect, useState} from "react";

type RefTypeForVisible = HTMLElement | undefined | null
const useVisible = <T extends RefTypeForVisible, T1 extends RefTypeForVisible>(
    ref: MutableRefObject<T>, rootRef?: MutableRefObject<T1>, rootMargin?: string) => {

    const [visible, setVisible] = useState<boolean | null>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.map((value) => {
                    setVisible(value.isIntersecting)
                })
            },
            {
                root: rootRef?.current,
                rootMargin: rootMargin
            }
        )

        if (ref.current) {
            observer.observe(ref.current)
        }

        return () => {
            observer.disconnect();
        }
    }, [])


    return [visible, setVisible]
}

export default useVisible;


