import requestClient from "@/services/request";
import {AxiosResponse} from "axios";
import {Response} from "@/services/base";
import {TableDataInfo, TableRequestParam} from "@/services/types";

export const createAlbum = () => {
    return requestClient.post<Response<void>, AxiosResponse<Response<void>>, null>("/image-gallery/album/create");
}

export interface ImageGalleryAlbum {
    id: number,
    userId: number,
    accessSecret: string,
    createTime: string,
    updateTime: string,
}

export type ImageGalleryAlbums = TableDataInfo<ImageGalleryAlbum>;

export const listAlbum = (tableRequestParam: TableRequestParam) => {
    return requestClient.get<Response<ImageGalleryAlbums>, AxiosResponse<Response<ImageGalleryAlbums>>, null>(
        "/api/v1/image-gallery/album/list",
        {
            params: tableRequestParam
        }
    )
}

export const getAlbum = (id: number) => {
    return requestClient.get<Response<ImageGalleryAlbum>, AxiosResponse<Response<ImageGalleryAlbum>>, null>(
        `/api/v1/image-gallery/album/get/${id}`
    );
}

export const deleteAlbum = (id: number) => {
    return requestClient.delete<Response<null>, AxiosResponse<Response<null>>, null>(
        `/api/v1/image-gallery/album/delete/${id}`
    )
}