import React, {Attributes, useEffect, useState} from "react";
import {LayoutImage2x2, Thumb} from "@/services/albumImage";
import api from "@/services/api";
import {PhotoIcon} from "@heroicons/react/24/solid";
import clsx from "clsx";

export interface ImagePanelProps {
    key: Attributes,
    item: LayoutImage2x2,
    secret: string,
    height?: number
}

interface ImagePanelCellProps {
    key: Attributes,
    item: Thumb,
    imageId: number,
    secret: string
}

const ImagePanelCell: React.FC<ImagePanelCellProps> = (props) => {
    const [imageUrl, setImageUrl] = useState<string>();

    useEffect(() => {
        api.albumImage.thumbnailLayout(props.item, props.secret)
            .then((res) => {
                setImageUrl(URL.createObjectURL(res.data))
            })
    }, [])

    return (
        <a href="#" className="h-auto group">
            <div className="w-full overflow-hidden rounded-lg">
                {
                    imageUrl
                        ?
                        <img
                            src={imageUrl}
                            alt="Tall slender porcelain bottle with natural clay textured body and cork stopper."
                            className="w-full object-cover object-center group-hover:opacity-75"
                        />
                        :
                        <div
                            className="w-full overflow-hidden rounded-lg bg-gray-200 dark:text-gray-600 flex justify-center items-center"
                        >
                            <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true"/>
                        </div>
                }

            </div>
        </a>
    )
}

const createThumbDivClass = (thumb: Thumb) => {
    const lay = thumb.type.slice(1)

    if (lay === "1x1") {
        return "";
    }

    if (lay === "2x1") {
        return "col-span-2";
    }

    return "row-span-2";
}

const ImagePanel: React.FC<ImagePanelProps> = (props) => {

    return (
        <div className="grid grid-rows-2 grid-cols-2 place-items-center">
            {
                props.item.thumbs.map((value, index, array) => {
                    return (
                        // <div className={clsx(createThumbDivClass(value), "p-1")}>
                        <div className={clsx(createThumbDivClass(value))}>
                            <ImagePanelCell
                                key={index}
                                item={value}
                                imageId={props.item.imageIds[index]}
                                secret={props.secret}/>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ImagePanel;