// import resolveConfig from 'tailwindcss/resolveConfig'
// import tailwindConfig from '../../tailwind.config.js'
//
// export const fullConfig = resolveConfig(tailwindConfig)

export enum BREAKPOINT {
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
    XL2 = '2xl',
}

const DEFAULT_SCREENS = {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
};

// export const screens = fullConfig.theme?.screens ?? DEFAULT_SCREENS;
export const screens = DEFAULT_SCREENS;